<template>
  <b-modal id="newRDV" ref="newRDV" scrollable @hide="close">
    <template #modal-header>
      <h5 style="text-transform: capitalize">
        {{ RDV.type.slice(9) }}
      </h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="createEvent">
      <div class="center">
        <b-form-group :label="$t('TYPE') + '*'">
          <b-form-select v-model="RDV.type" :options="options"></b-form-select>
          <div v-if="erreurlist.type" class="error-message">
            <ul v-if="Array.isArray(erreurlist.type)">
              <span v-for="(e, index) in erreurlist.type" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.type }}</span>
          </div>
          <div v-if="v$.RDV.type.$error" class="error-message">
            {{ $t("REQUIRED_FIELD") }}
          </div>
        </b-form-group>
        <b-form-group :label="$t('TITLE') + '*'" label-for="titre">
          <b-form-input
            v-model="v$.RDV.titre.$model"
            oninput="this.value = this.value.toUpperCase()"
            :state="validateState('titre')"
            aria-describedby="titre-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.RDV.titre.$errors"
            id="titre-feedback"
          ></error-handle>
          <div v-if="erreurlist.titre" class="error-message">
            <ul v-if="Array.isArray(erreurlist.titre)">
              <span v-for="(e, index) in erreurlist.titre" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.titre }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('DESC')">
          <b-form-textarea
            v-model="v$.RDV.description.$model"
            :placeholder="$t('DESC')"
            rows="3"
            max-rows="6"
            :state="validateState('description')"
            aria-describedby="description-feedback"
          ></b-form-textarea>
          <error-handle
            :list="v$.RDV.description.$errors"
            id="description-feedback"
          ></error-handle>
          <div v-if="erreurlist.description" class="error-message">
            <ul v-if="Array.isArray(erreurlist.description)">
              <span v-for="(e, index) in erreurlist.description" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.description }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('DATE_BEGIN') + '*'">
          <b-form-input
            type="datetime-local"
            v-model="v$.RDV.date_debut.$model"
            :state="validateState('date_debut')"
            aria-describedby="date_debut-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.RDV.date_debut.$errors"
            id="date_debut-feedback"
          ></error-handle>
          <div v-if="erreurlist.date_debut" class="error-message">
            <ul v-if="Array.isArray(erreurlist.date_debut)">
              <span v-for="(e, index) in erreurlist.date_debut" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.date_debut }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('DATE_END') + '*'">
          <b-form-input
            type="datetime-local"
            v-model="v$.RDV.date_fin.$model"
            :state="validateState('date_fin')"
            aria-describedby="date_fin-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.RDV.date_fin.$errors"
            id="date_fin-feedback"
          ></error-handle>
          <div v-if="erreurlist.date_fin" class="error-message">
            {{ $t("REQUIRED_FIELD_DATE") }}
          </div>
        </b-form-group>
        <b-form-group
          :label="$t('ENTREPRISES') + '*'"
          v-if="isSuperAdmin || isCommercialBatigo"
        >
          <multiselect
            v-model="RDV.entreprise"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllentreprises"
            label="lib"
            track-by="id"
            @input="chooseEnt()"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
          <div v-if="erreurlist.entreprise_id" class="error-message">
            <ul v-if="Array.isArray(erreurlist.entreprise_id)">
              <span v-for="(e, index) in erreurlist.entreprise_id" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.entreprise_id }}</span>
          </div>
        </b-form-group>
        <b-form-group
          :label="$t('CUSTOMER')"
          v-if="
            isSuperAdmin ||
            isentrepriseAdmin ||
            isTechnicien ||
            isAssistant ||
            isCommercial
          "
        >
          <searchInput
            modalename="client"
            :list="getAllclients"
            :loader="getclientLoading"
            label="full_name"
            :searchFunc="all_clients"
            @searchfilter="selectClient($event)"
            :placeholder="$t('CUSTOMER')"
            :filtre="{
              entreprise_id: RDV.entreprise
                ? RDV.entreprise.id
                : getOnlineUser.entreprise.id,
            }"
          >
          </searchInput>
          <div v-if="erreurlist.user_id" class="error-message">
            <ul v-if="Array.isArray(erreurlist.user_id)">
              <span v-for="(e, index) in erreurlist.user_id" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.user_id }}</span>
          </div>
        </b-form-group>
        <b-form-group
          :label="$t('USER')"
          v-if="isSuperAdmin || isentrepriseAdmin || isAssistant"
        >
          <searchInput
            :list="getAllUsers"
            :loader="getOnlineUserLoading"
            label="full_name"
            :searchFunc="all_users"
            @searchfilter="selectuser($event)"
            :filtre="{
              entreprise_id: RDV.entreprise
                ? RDV.entreprise.id
                : getOnlineUser.entreprise.id,
            }"
          ></searchInput>
          <div v-if="erreurlist.user_id" class="error-message">
            <ul v-if="Array.isArray(erreurlist.user_id)">
              <span v-for="(e, index) in erreurlist.user_id" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.user_id }}</span>
          </div>
        </b-form-group>

        <b-form-group
          :label="$t('CITY')"
          label-for="ville"
          v-if="!callRecallStore"
        >
          <b-form-input
            id="ville"
            :placeholder="$t('CITY')"
            v-model="v$.RDV.ville.$model"
            oninput="this.value = this.value.toUpperCase()"
            :state="validateState('ville')"
            aria-describedby="ville-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.RDV.ville.$errors"
            id="ville-feedback"
          ></error-handle>
          <div v-if="erreurlist.ville" class="error-message">
            <ul v-if="Array.isArray(erreurlist.ville)">
              <span v-for="(e, index) in erreurlist.ville" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.ville }}</span>
          </div>
        </b-form-group>

        <b-form-group
          :label="$t('ADRESS')"
          label-for="adresse"
          v-if="!callRecallStore"
        >
          <b-form-input
            id="adresse"
            :placeholder="$t('ADRESS')"
            v-model="v$.RDV.adresse.$model"
            :state="validateState('adresse')"
            aria-describedby="adresse-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.RDV.adresse.$errors"
            id="adresse-feedback"
          ></error-handle>
          <div v-if="erreurlist.adresse" class="error-message">
            <ul v-if="Array.isArray(erreurlist.adresse)">
              <span v-for="(e, index) in erreurlist.adresse" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.adresse }}</span>
          </div>
        </b-form-group>

        <b-form-group
          :label="$t('POSTAL_CODE')"
          label-for="cp"
          v-if="!callRecallStore"
        >
          <b-form-input
            id="cp"
            :placeholder="$t('POSTAL_CODE')"
            v-model="v$.RDV.cp.$model"
            type="number"
            max="10"
            :state="validateState('cp')"
            aria-describedby="cp-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.RDV.cp.$errors"
            id="cp-feedback"
          ></error-handle>
          <div v-if="erreurlist.cp" class="error-message">
            <ul v-if="Array.isArray(erreurlist.cp)">
              <span v-for="(e, index) in erreurlist.cp" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.cp }}</span>
          </div>
        </b-form-group>
      </div>
    </form>
    <template #modal-footer>
      <b-alert variant="warning" show v-if="errorS">
        {{ errorS }}
      </b-alert>
      <div class="double">
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button variant="success" @click="createEvent">
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getagendaLoading" class="ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import searchInput from "@/components/ui/searchInput";
import errorHandle from "../ui/errorHandle.vue";
import {
  required,
  maxLength,
  minLength,
  requiredUnless,
  numeric,
} from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchInput,
    errorHandle,
  },
  data() {
    return {
      RDV: {
        user: null,
        titre: null,
        type: "activity.rendez-vous",
        description: null,
        date_debut: null,
        date_fin: null,
        entreprise_id: null,
        user_id: null,
        client_id: null,
        done: 0,
        cp: null,
        ville: null,
        adresse: null,
      },
      erreurlist: {
        user: null,
        titre: null,
        type: null,
        description: null,
        date_debut: null,
        date_fin: null,
        entreprise_id: null,
        user_id: null,
        client_id: null,
        cp: null,
        ville: null,
        adresse: null,
      },
      errorS: null,
      options: [
        { value: "activity.appel", text: "Appel" },
        { value: "activity.rendez-vous", text: "Rendez-vous" },
        { value: "activity.tache", text: "Tache" },
      ],
      box: "",
    };
  },
  validations() {
    return {
      RDV: {
        type: { required },
        titre: { required, maxLength: maxLength(100) },
        description: { maxLength: maxLength(1000) },
        date_debut: { required },
        date_fin: { required },
        adresse: {
          requiredUnless: requiredUnless(this.RDV.type == "activity.appel"),
          maxLength: maxLength(30),
        },
        cp: {
          requiredUnless: requiredUnless(this.RDV.type == "activity.appel"),
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        ville: {
          requiredUnless: requiredUnless(this.RDV.type == "activity.appel"),
          maxLength: maxLength(30),
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "all_users",
      "store_event",
      "all_entreprises",
      "all_clients",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.RDV[name];
      return $dirty ? !$error : null;
    },

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },

    selectClient(client) {
      if (!client) {
        this.RDV.client = null;
        this.RDV.adresse = "";
        this.RDV.ville = "";
        this.RDV.cp = "";
        return;
      }
      this.RDV.client = client;
      this.RDV.adresse = client.rue;
      this.RDV.ville = client.ville;
      this.RDV.cp = client.cp;
    },
    selectuser(user) {
      this.RDV.user = user;
    },
    chooseEnt() {
      this.all_clients({
        page: 1,
        per_page: 10,
        entreprise_id: this.RDV.entreprise
          ? this.RDV.entreprise.id
          : this.getOnlineUser.entreprise.id,
      });
      this.all_users({
        page: 1,
        per_page: 9999,
        entreprise_id: this.RDV.entreprise
          ? this.RDV.entreprise.id
          : this.getOnlineUser.entreprise.id,
      });
    },

    resetModal() {
      this.errorS = null;
      this.$refs["newRDV"].hide();
      this.RDV = {
        user: null,
        titre: null,
        type: "activity.rendez-vous",
        description: null,
        date_debut: null,
        date_fin: null,
        entreprise_id: null,
        user_id: null,
        done: 0,
        client: null,
        adresse: null,
        ville: null,
        cp: null,
      };
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },

    close(bv) {
      if (this.$refs["newRDV"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async createEvent() {
      await this.v$.$validate();
      const isFormCorrect = await this.v$.RDV.$error;
      if (isFormCorrect) return;
      this.errorS = null;
      this.erreurlist = {
        user: null,
        titre: null,
        type: null,
        description: null,
        date_debut: null,
        date_fin: null,
        entreprise_id: null,
        user_id: null,
        client_id: null,
        cp: null,
        ville: null,
        adresse: null,
      };
      if (this.RDV.date_debut) {
        this.RDV.date_debut = moment(this.RDV.date_debut).format(
          "YYYY-MM-DD HH:mm"
        );
      }
      if (this.RDV.date_fin) {
        this.RDV.date_fin = moment(this.RDV.date_fin).format(
          "YYYY-MM-DD HH:mm"
        );
      }

      this.RDV.entreprise_id = this.RDV.entreprise
        ? this.RDV.entreprise.id
        : this.getOnlineUser.entreprise.id;

      this.RDV.user_id = this.RDV.user
        ? this.RDV.user.id
        : this.getOnlineUser.id;

      var payload = {
        date_debut: this.RDV.date_debut,
        date_fin: this.RDV.date_fin,
        description: this.RDV.description,
        done: 0,
        entreprise_id: this.RDV.entreprise_id,
        titre: this.RDV.titre,
        type: this.RDV.type,
        user_id: this.RDV.user_id,
      };

      if (this.RDV.client) {
        payload.client_id = this.RDV.client.id;
      }
      if (this.RDV.adresse) payload.adresse = this.RDV.adresse;

      if (this.RDV.ville) payload.ville = this.RDV.ville;

      if (this.RDV.cp) payload.cp = this.RDV.cp;

      await this.store_event(payload)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    handleStoreClient() {
      this.$root.$emit("bv::show::modal", "clientStoreModal");
    },
  },
  computed: {
    ...mapGetters([
      "getAllUsers",
      "getOnlineUser",
      "getAllentreprises",
      "getAgenda",
      "getagendaLoading",
      "getAllclients",
      "getSelectedLang",
      "getclientLoading",
      "getOnlineUserLoading",
    ]),

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },

    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },

    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },

    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },

    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isTechnicien() {
      return this.getOnlineUser.role == "user.technicien";
    },
    isUserCLient() {
      return this.getOnlineUser.role == "user.client";
    },

    callRecallStore() {
      return this.RDV.type == "activity.appel";
    },
  },

  mounted() {
    if (this.isSuperAdmin) {
      if (!this.getAllentreprises) {
        this.all_entreprises({
          page: 1,
          per_page: 99999,
        });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.newClient {
  background: #e4261b;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #e4261b !important;
  }
}
</style>
