<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("AGENDA") }}</h1>
      <b-button size="sm" variant="success" @click="typeRDV"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}
      </b-button>
      <!------------------------------------------------------------------end-choix------------------------------------------------------------------------------------------------------------->
    </div>

    <storeModalVue />

    <div
      class="content"
      :class="{ 'min-calendar': detailDay && type != 'day' }"
    >
      <v-sheet>
        <v-toolbar flat>
          <div class="all">
            <div class="filtreparte">
              <searchInput
                style="width: auto"
                class="mr-2"
                v-if="isSuperAdmin && getAllentreprises && defaultcompany"
                :list="getAllentreprises"
                :loader="getentrepriseLoading"
                label="lib"
                :searchFunc="all_entreprises"
                @searchfilter="selectentreprise($event)"
                :placeholder="$t('COMPANY_ID')"
                :selected="defaultcompany"
              >
              </searchInput>

              <searchInput
                style="width: auto"
                class="mr-2"
                v-if="
                  (isSuperAdmin || isentrepriseAdmin || isAssistant) &&
                  getAllUsers &&
                  defaultuser
                "
                :list="[{ full_name: 'Tous', id: null }, ...getAllUsers]"
                :loader="getOnlineUserLoading"
                label="full_name"
                :searchFunc="all_users"
                @searchfilter="selectuser($event)"
                :placeholder="$t('Utilisateur')"
                :filtre="{
                  entreprise_id: filtre_entreprise
                    ? filtre_entreprise
                    : getOnlineUser.entreprise.id,
                }"
                :selected="defaultuser"
              >
              </searchInput>

              <multiselect
                class="mr-2"
                v-if="isSuperAdmin || isentrepriseAdmin || isAssistant"
                v-model="filtrestatut"
                label="text"
                :options="statutoption"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                :placeholder="$t('STATUT')"
                @input="selectstatut()"
              >
                <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
              </multiselect>
            </div>
            <div class="secondpart">
              <!-- <b-button @click="filtreType('activity.finished')" class="filtreterminer">
            {{ $t("FINISHED") }}
          </b-button> -->
              <div class="nbmonth">
                <span class="total">
                  {{ getAgendaRows }} {{ $t("RENDEZVOUS") }}
                </span>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-toolbar-title
                  v-if="$refs.calendar && !detailDay"
                  class="title"
                >
                  <span v-if="type == 'day'">{{
                    $refs.calendar.value.slice(-2)
                  }}</span>
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="next"
                  style="margin-right: 8px"
                >
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <v-spacer></v-spacer>
              <div class="filtremois">
                <b-form-select
                  v-model="type"
                  :options="typeToLabel"
                  @change="scrollUp"
                ></b-form-select>
              </div>
            </div>
          </div>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :type="type"
          format="24hr"
          :events="events"
          @click:event="showEvent"
          @click:date="viewDay"
          @click:more="viewDay"
          :weekdays="format"
          @change="pullup"
        >
          <template v-slot:event="{ event }" style="height: 60%">
            <div :style="{ 'border-color': event.color }" class="event">
              <h1>{{ event.name }}</h1>
              <div class="statutevent" v-if="event.statut == 'pending'">
                {{ $t("A FAIRE") }}
              </div>
              <div class="statutevent" v-if="event.statut == 'late'">
                {{ $t("En retard") }}
              </div>
              <div
                v-if="event.statut == 'late' && event.done == 0"
                class="statutsecond"
              >
                {{ $t("A FAIRE") }}
              </div>
              <div class="statutevent" v-if="event.statut == 'done'">
                {{ $t("FAIT") }}
              </div>
              <p v-if="event.client">
                {{ event.client.nom }} {{ event.client.prenom }}
              </p>
              <p>{{ event.cp }}</p>
              <p v-if="type == 'day'">{{ event.description }}</p>
              <span>
                {{ event.start.slice(-8, -3) }}-{{ event.end.slice(-8, -3) }}
              </span>
            </div>
          </template>
        </v-calendar>
        <!-- --------------------------------------------------------modale affichage de donnée------------------------------------------------------------------------ -->
        <b-modal id="affichagedonnée" ref="affichagedonnée" scrollable>
          <template #modal-header="{ close }">
            <h5>{{ selectedEvent.name }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>
          <form @submit.prevent="handleUpdate">
            <div class="center">
              <div class="toppart">
                <div
                  class="circle"
                  :class="{
                    pro: selectedEvent.type == 'activity.rendez-vous',
                    App: selectedEvent.type == 'activity.appel',
                    tach: selectedEvent.type == 'activity.tache',
                  }"
                  v-if="selectedEvent.type"
                >
                  {{ selectedEvent.type.slice(9, 11) }}
                </div>
                <div class="info">
                  <div>
                    <h5 v-if="selectedEvent.name">{{ selectedEvent.name }}</h5>
                    <p>
                      <span v-if="selectedEvent.type">{{
                        selectedEvent.type.slice(9)
                      }}</span>
                    </p>
                  </div>
                  <div class="modifsup">
                    <b-button
                      class="button-succes-style"
                      size="sm"
                      variant="danger"
                      @click.prevent.stop="handleDelete()"
                      v-if="isSuperAdmin"
                    >
                      <font-awesome-icon icon="trash" />
                    </b-button>
                    <b-button
                      class="button-succes-style"
                      size="sm"
                      variant="success"
                      @click.prevent.stop="handleUpdate(data)"
                      v-if="isSuperAdmin"
                    >
                      <font-awesome-icon icon="pencil-alt" />
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="blocinfo">
                <div class="statut">
                  <span
                    v-if="selectedEvent.statut == 'pending'"
                    class="pending"
                    >{{ $t("A FAIRE") }}</span
                  >
                  <span v-else-if="selectedEvent.statut == 'late'" class="late"
                    >{{ $t("En retard") }}
                  </span>
                  <span
                    v-else-if="selectedEvent.statut == 'done'"
                    class="done"
                    >{{ $t("FAIT") }}</span
                  >

                  <div
                    v-if="
                      selectedEvent.statut == 'late' &&
                      selectedEvent.done == '0'
                    "
                    class="eventstatut"
                  >
                    {{ $t("A FAIRE") }}
                  </div>
                </div>
                <div class="onligne">
                  <label class="titre">{{ $t("TITLE") }}:</label>
                  <span>{{ selectedEvent.name }}</span>
                </div>
                <div class="onligne">
                  <label class="titre">{{ $t("DATE") }}:</label>
                  <span
                    >{{
                      moment(selectedEvent.start).format("DD-MM-YYYY hh:mm")
                    }}
                    -
                    {{
                      moment(selectedEvent.end).format("DD-MM-YYYY hh:mm")
                    }}</span
                  >
                </div>
                <div class="onligne">
                  <label class="titre">{{ $t("DESC") }}:</label>
                  <span>{{ selectedEvent.description }}</span>
                </div>
                <div v-if="v$.selectedEvent.end.$error" class="error-message">
                  {{ $t("REQUIRED_FIELD") }}
                </div>
                <div class="onligne" v-if="selectedEvent.entreprise">
                  <label class="titre">{{ $t("ENTREPRISES") }}:</label>
                  <span v-if="selectedEvent.entreprise">{{
                    selectedEvent.entreprise.lib
                  }}</span>
                </div>
                <div class="onligne" v-if="selectedEvent.client">
                  <label class="titre">{{ $t("CUSTOMER") }}:</label>
                  <span v-if="selectedEvent.client">{{
                    selectedEvent.client.nom
                  }}</span>
                  <span v-if="selectedEvent.client">{{
                    selectedEvent.client.prenom
                  }}</span>
                </div>
                <div class="onligne" v-if="selectedEvent.user">
                  <label class="titre">{{ $t("USER") }}:</label>
                  <span v-if="selectedEvent.user">{{
                    selectedEvent.user.nom
                  }}</span>
                  <span v-if="selectedEvent.user">{{
                    selectedEvent.user.prenom
                  }}</span>
                </div>
                <div class="onligne">
                  <label class="titre">{{ $t("ADRESS") }}:</label>
                  <a
                    :href="
                      'https://www.google.tn/maps/search/' +
                      selectedEvent.adresse +
                      selectedEvent.ville +
                      selectedEvent.cp
                    "
                    target="_blank"
                  >
                    <span>
                      {{ selectedEvent.adresse }}-{{ selectedEvent.ville }}-{{
                        selectedEvent.cp
                      }}</span
                    ></a
                  >
                </div>
              </div>
            </div>
          </form>

          <template #modal-footer>
            <b-alert variant="warning" show v-if="errorU">
              {{ errorU }}
            </b-alert>
            <div
              class="double"
              v-if="
                isSuperAdmin ||
                isentrepriseAdmin ||
                isAssistant ||
                getOnlineUser.id == selectedEvent.user.id
              "
            >
              <b-button variant="danger" @click="handleDelete">
                <div class="block-spinner">
                  {{ $t("DELETE") }}
                </div>
              </b-button>
              <b-button variant="success" @click="handleUpdate">
                <div class="block-spinner">
                  {{ $t("EDIT") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>

        <!-- ----------------------------------------------------------------modale-modification----------------------------------------------------------------------------------------------- -->
        <b-modal id="eventDetail" ref="eventDetail" scrollable>
          <template #modal-header>
            <h5>{{ selectedEvent.name }}</h5>
            <b-button size="sm" @click="resetModal()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>
          <form @submit.prevent="editEvent">
            <div class="center">
              <b-form-group :label="$t('TITLE')">
                <b-form-input
                  v-model="v$.selectedEvent.name.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  :state="validateState('name')"
                  aria-describedby="name-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.selectedEvent.name.$errors"
                  id="name-feedback"
                ></error-handle>
                <div v-if="erreurlist.titre" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.titre)">
                    <span v-for="(e, index) in erreurlist.titre" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.titre }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('TYPE')">
                <b-form-select
                  style="max-width: 100%"
                  v-model="selectedEvent.type"
                  :options="options"
                ></b-form-select>
                <div v-if="erreurlist.type" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.type)">
                    <span v-for="(e, index) in erreurlist.type" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.type }}</span>
                </div>
                <div v-if="v$.selectedEvent.type.$error" class="error-message">
                  {{ $t("REQUIRED_FIELD") }}
                </div>
              </b-form-group>

              <b-form-group :label="$t('DESC')">
                <b-form-textarea
                  v-model="v$.selectedEvent.description.$model"
                  :placeholder="$t('DESC')"
                  rows="3"
                  max-rows="6"
                  :state="validateState('description')"
                  aria-describedby="description-feedback"
                ></b-form-textarea>
                <error-handle
                  :list="v$.selectedEvent.description.$errors"
                  id="description-feedback"
                ></error-handle>
                <div v-if="erreurlist.description" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.description)">
                    <span
                      v-for="(e, index) in erreurlist.description"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.description }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('DATE_BEGIN')">
                <b-form-input
                  type="datetime-local"
                  v-model="v$.selectedEvent.start.$model"
                  :state="validateState('start')"
                  aria-describedby="start-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.selectedEvent.start.$errors"
                  id="start-feedback"
                ></error-handle>
                <div v-if="erreurlist.date_debut" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.date_debut)">
                    <span
                      v-for="(e, index) in erreurlist.date_debut"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.date_debut }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('DATE_END')">
                <b-form-input
                  type="datetime-local"
                  v-model="v$.selectedEvent.end.$model"
                  :state="validateState('end')"
                  aria-describedby="end-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.selectedEvent.end.$errors"
                  id="end-feedback"
                ></error-handle>
                <div v-if="erreurlist.date_fin" class="error-message">
                  {{ $t("REQUIRED_FIELD_DATE") }}
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('ENTREPRISES')"
                v-if="(isSuperAdmin || isCommercialBatigo) && !callRecallUpdate"
              >
                <multiselect
                  v-if="getAllentreprises"
                  v-model="selectedEvent.entreprise"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllentreprises"
                  label="lib"
                  track-by="id"
                  @input="chooseEnt()"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
                <div v-if="erreurlist.entreprise_id" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.entreprise_id)">
                    <span
                      v-for="(e, index) in erreurlist.entreprise_id"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.entreprise_id }}</span>
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('CUSTOMER')"
                v-if="
                  isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial
                "
              >
                <multiselect
                  v-if="getAllclients"
                  v-model="selectedEvent.client"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  @input="selectClient($event)"
                  :options="getAllclients"
                  label="nom"
                  track-by="id"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
                <div v-if="erreurlist.user_id" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.user_id)">
                    <span v-for="(e, index) in erreurlist.user_id" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.user_id }}</span>
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('USER')"
                v-if="isSuperAdmin || isentrepriseAdmin || isAssistant"
              >
                <multiselect
                  v-if="getAllUsers"
                  v-model="selectedEvent.user"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllUsers"
                  label="nom"
                  track-by="id"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
                <div v-if="erreurlist.user_id" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.user_id)">
                    <span v-for="(e, index) in erreurlist.user_id" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.user_id }}</span>
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('CITY')"
                label-for="ville"
                v-if="!callRecallUpdate"
              >
                <b-form-input
                  id="ville"
                  :placeholder="$t('CITY')"
                  v-model="v$.selectedEvent.ville.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  :state="validateState('ville')"
                  aria-describedby="ville-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.selectedEvent.ville.$errors"
                  id="ville-feedback"
                ></error-handle>
                <div v-if="erreurlist.ville" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.ville)">
                    <span v-for="(e, index) in erreurlist.ville" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.ville }}</span>
                </div>
              </b-form-group>
              <b-form-group
                :label="$t('ADRESS')"
                label-for="adresse"
                v-if="!callRecallUpdate"
              >
                <b-form-input
                  id="adresse"
                  :placeholder="$t('ADRESS')"
                  v-model="v$.selectedEvent.adresse.$model"
                  :state="validateState('adresse')"
                  aria-describedby="adresse-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.selectedEvent.adresse.$errors"
                  id="adresse-feedback"
                ></error-handle>
                <div v-if="erreurlist.adresse" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.adresse)">
                    <span v-for="(e, index) in erreurlist.adresse" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.adresse }}</span>
                </div>
              </b-form-group>
              <b-form-group
                :label="$t('POSTAL_CODE')"
                label-for="cp"
                v-if="!callRecallUpdate"
              >
                <b-form-input
                  id="cp"
                  :placeholder="$t('POSTAL_CODE')"
                  v-model="v$.selectedEvent.cp.$model"
                  type="number"
                  :state="validateState('cp')"
                  aria-describedby="cp-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.selectedEvent.cp.$errors"
                  id="cp-feedback"
                ></error-handle>
                <div v-if="erreurlist.cp" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.cp)">
                    <span v-for="(e, index) in erreurlist.cp" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.cp }}</span>
                </div>
              </b-form-group>

              <b-form-checkbox
                id="checkbox-1"
                v-model="selectedEvent.done"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
              >
                {{ $t("FINISHED") }}
              </b-form-checkbox>
            </div>
          </form>

          <template #modal-footer>
            <b-alert variant="warning" show v-if="errorU">
              {{ errorU }}
            </b-alert>
            <div class="double">
              <b-button variant="danger" @click="resetModal">
                <div class="block-spinner">
                  {{ $t("CANCEL") }}
                </div>
              </b-button>
              <b-button variant="success" @click="editEvent">
                <div class="block-spinner">
                  {{ $t("EDIT") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>
        <!-- ----------------------------------------------------------------------end-modification------------------------------------------------------------------------------------------------ -->
      </v-sheet>
    </div>
    <div class="infoDay" v-if="detailDay && type != 'day' && !getagendaLoading">
      <div class="cross" @click="detailDay = !detailDay">x</div>
      <v-calendar
        ref="calendarDay"
        v-model="focus"
        type="day"
        format="24hr"
        event-overlap-mode="column"
        :events="events"
        @click:event="showEvent"
      ></v-calendar>
    </div>
    <div class="loading" v-if="getagendaLoading">
      <b-spinner label="Spinning"></b-spinner>
    </div>

    <deleteModalVue
      :loader="getagendaLoading"
      :elemToDelete="selectedEvent"
      :elemDelete="delete_event"
      @updateList="setup($event)"
    ></deleteModalVue>
    <CommentMarche to="/how-it-work/agenda"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import searchInput from "@/components/ui/searchInput";
import storeModalVue from "../components/Ajoutmodel/activitymodale.vue";
import deleteModalVue from "../components/ui/deleteModal.vue";
import errorHandle from "../components/ui/errorHandle.vue";
import {
  required,
  maxLength,
  minLength,
  requiredUnless,
  numeric,
} from "@vuelidate/validators";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchInput,
    storeModalVue,
    deleteModalVue,
    errorHandle,
    CommentMarche,
  },
  data() {
    return {
      messages: "Veuillez remplir tous les champs du formulaire",
      showDismissibleAlert: false,
      format: [1, 2, 3, 4, 5, 6, 0],
      date: null,
      errorU: null,
      focus: "",
      type: "month",
      typeToLabel: [
        { value: "month", text: "Month" },
        { value: "week", text: "Week" },
        { value: "day", text: "Day" },
      ],
      selectedEvent: {
        type: null,
      },
      selectedElement: null,
      selectedOpen: false,
      events: [],
      names: ["Appel", "Rendez-vous", "Rappel", "Tache"],
      month: new Date().getMonth() + 1,
      statutoption: [
        { value: "null", text: this.$t("TOUS") },
        { value: "pending", text: this.$t("A FAIRE") },
        { value: "late", text: this.$t("En retard") },
        { value: "done", text: this.$t("FAIT") },
      ],
      erreurlist: {
        user: null,
        name: null,
        type: null,
        description: null,
        date_debut: null,
        date_fin: null,
        entreprise_id: null,
        user_id: null,
        client_id: null,
        cp: null,
        ville: null,
        adresse: null,
      },
      entrepriseInfo: null,
      filtrestatut: null,
      filtreUser: null,
      filtreColor: null,
      options: [
        { value: "activity.appel", text: "Appel" },
        { value: "activity.rendez-vous", text: "Rendez-vous" },
        { value: "activity.tache", text: "Tache" },
      ],
      detailDay: false,
      debut: null,
      fin: null,
      defaultcompany: null,
      defaultuser: null,
      filtre_entreprise: null,
    };
  },
  validations() {
    return {
      selectedEvent: {
        name: { required, maxLength: maxLength(100) },
        description: { maxLength: maxLength(1000) },
        type: { required },
        start: { required },
        end: { required },
        adresse: {
          requiredUnless: requiredUnless(
            this.selectedEvent.type == "activity.appel"
          ),
          maxLength: maxLength(30),
        },
        cp: {
          requiredUnless: requiredUnless(
            this.selectedEvent.type == "activity.appel"
          ),
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        ville: {
          requiredUnless: requiredUnless(
            this.selectedEvent.type == "activity.appel"
          ),
          maxLength: maxLength(30),
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "all_users",
      "store_event",
      "all_entreprises",
      "all_rendezvous",
      "delete_event",
      "edit_event",
      "all_clients",
      "activity",
      "pays",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.selectedEvent[name];
      return $dirty ? !$error : null;
    },

    traduction() {
      switch (this.getSelectedLang.iso_code) {
        case "fr": {
          this.typeToLabel = [
            { value: "month", text: "Mois" },
            { value: "week", text: "Semaine" },
            { value: "day", text: "Jour" },
          ];
          break;
        }
        case "ar": {
          this.typeToLabel = [
            { value: "month", text: "شهر" },
            { value: "week", text: "أسبوع" },
            { value: "day", text: "يوم" },
          ];
          break;
        }
        case "pl": {
          this.typeToLabel = [
            { value: "month", text: "Miesiąc" },
            { value: "week", text: "Tydzień" },
            { value: "day", text: "Dzień" },
          ];
          break;
        }
        case "prt": {
          this.typeToLabel = [
            { value: "month", text: "Mês" },
            { value: "week", text: "Semana" },
            { value: "day", text: "Dia" },
          ];
          break;
        }
        case "Rou": {
          this.typeToLabel = [
            { value: "month", text: "Lună" },
            { value: "week", text: "Săptămână" },
            { value: "day", text: "Zi" },
          ];
          break;
        }

        default:
          break;
      }
    },

    typeRDV() {
      this.$root.$emit("bv::show::modal", "newRDV");
    },

    handleUpdate() {
      this.$refs["eventDetail"].show();
    },
    resetModal() {
      this.erreurlist = {
        user: null,
        titre: null,
        type: null,
        description: null,
        date_debut: null,
        date_fin: null,
        entreprise_id: null,
        user_id: null,
        client_id: null,
        cp: null,
        ville: null,
        adresse: null,
      };
      this.errorU = null;
      this.$refs["eventDetail"].hide();
    },
    async editEvent() {
      const isFormCorrect = await this.v$.selectedEvent.$error;
      if (isFormCorrect) return;
      this.errorU = null;
      this.erreurlist = {
        user: null,
        name: null,
        type: null,
        description: null,
        date_debut: null,
        date_fin: null,
        entreprise_id: null,
        user_id: null,
        client_id: null,
        cp: null,
        ville: null,
        adresse: null,
      };
      var data = {
        titre: this.selectedEvent.name,
        description: this.selectedEvent.description,
        type: this.selectedEvent.type,
        date_debut: this.selectedEvent.start,
        date_fin: this.selectedEvent.end,
        client_id: this.selectedEvent.client
          ? this.selectedEvent.client.id
          : this.selectedEvent.client_id,
        entreprise_id: this.selectedEvent.entreprise
          ? this.selectedEvent.entreprise.id
          : this.selectedEvent.entreprise_id,
        user_id: this.selectedEvent.user
          ? this.selectedEvent.user.id
          : this.selectedEvent.user_id,
        done: this.selectedEvent.done,
        id: this.selectedEvent.id,
        cp: this.selectedEvent.cp,
        ville: this.selectedEvent.ville,
        adresse: this.selectedEvent.adresse,
      };

      data.date_debut = moment(this.selectedEvent.start).format(
        "YYYY-MM-DD HH:mm"
      );

      data.date_fin = moment(this.selectedEvent.end).format("YYYY-MM-DD HH:mm");

      await this.edit_event(data)
        .then(() => {
          this.hideModal("eventDetail");
          this.hideModal("affichagedonnée");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },

    getEvents() {
      var payload = {
        entreprise_id: this.getOnlineUser.entreprise.id,
        date_debut: this.debut.date,
        date_fin: this.fin.date,
        user_id: this.getOnlineUser.id,
      };

      if (!this.isSuperAdmin) payload.user_id = this.getOnlineUser.id;

      this.all_rendezvous(payload).then(() => {
        this.events = this.getAgenda;
      });
    },

    selectentreprise(event) {
      this.filtre_entreprise = event.id;
      if (this.isSuperAdmin) {
        this.all_rendezvous({
          entreprise_id: event.id ? event.id : this.getOnlineUser.entreprise.id,
          date_debut: this.debut.date,
          date_fin: this.fin.date,
        }).then(() => {
          this.events = this.getAgenda;
        });
      } else {
        this.all_rendezvous({
          entreprise_id: this.getOnlineUser.entreprise.id,
          user_id: this.getOnlineUser.id,
          date_debut: this.debut.date,
          date_fin: this.fin.date,
        }).then(() => {
          this.events = this.getAgenda;
        });
      }
      if (this.isSuperAdmin) {
        this.all_clients({
          page: 1,
          per_page: 99999,
          entreprise_id: event.id,
        });
      } else {
        this.all_clients({
          page: 1,
          per_page: 99999,
          entreprise_id: this.getOnlineUser.entreprise.id,
        });
      }
      if (this.isSuperAdmin) {
        this.all_users({
          page: 1,
          per_page: 99999,
          entreprise_id: event.id,
        });
      } else {
        this.all_users({
          page: 1,
          per_page: 99999,
          entreprise_id: this.getOnlineUser.entreprise.id,
        });
      }
    },
    selectstatut() {
      if (this.filtrestatut) {
        var data = {
          entreprise_id: this.filtre_entreprise,
          user_id: this.filtreUser,
          date_debut: this.debut.date,
          date_fin: this.fin.date,
        };
        if (this.filtrestatut.value != "null") {
          data.filtrestatut = this.filtrestatut.value;
        }

        this.all_rendezvous(data).then(() => {
          this.events = this.getAgenda;
        });
      } else {
        this.all_rendezvous({
          entreprise_id: this.getOnlineUser.entreprise.id,
          user_id: this.filtreUser,
          date_debut: this.debut.date,
          date_fin: this.fin.date,
          filtrestatut: this.filtrestatut.value,
        }).then(() => {
          this.events = this.getAgenda;
        });
      }
    },

    viewDay({ date }) {
      if (this.date == date) this.detailDay = !this.detailDay;
      else this.detailDay = true;
      this.date = date;
      this.focus = date;
      window.scrollTo(0, document.body.scrollHeight);
    },

    pullup({ start, end }) {
      this.debut = start;
      this.fin = end;
    },

    async prev() {
      if (!this.detailDay) {
        await this.$refs.calendar.prev();
        this.$refs.calendar.scrollToTime("06:00");
      } else {
        await this.$refs.calendarDay.prev();
      }
      this.interval();
    },
    async next() {
      if (!this.detailDay) {
        await this.$refs.calendar.next();
        this.$refs.calendar.scrollToTime("06:00");
      } else {
        await this.$refs.calendarDay.next();
      }
      this.interval();
    },

    interval() {
      this.all_rendezvous({
        entreprise_id: this.filtre_entreprise
          ? this.filtre_entreprise
          : this.getOnlineUser.entreprise.id,
        user_id: this.filtreUser,
        date_debut: this.debut.date,
        date_fin: this.fin.date,
      }).then(() => {
        this.events = this.getAgenda;
      });
    },

    showEvent({ nativeEvent, event }) {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => (this.selectedOpen = true))
      );
      if (
        this.selectedEvent.client &&
        this.selectedEvent.type != "activity.appel"
      ) {
        if (!this.selectedEvent.adresse)
          this.selectedEvent.adresse = this.selectedEvent.client.rue;
        if (!this.selectedEvent.ville)
          this.selectedEvent.ville = this.selectedEvent.client.ville;
        if (!this.selectedEvent.cp)
          this.selectedEvent.cp = this.selectedEvent.client.cp;
      }

      nativeEvent.stopPropagation();
      this.$refs["affichagedonnée"].show();
    },
    selectuser(event) {
      if (this.entrepriseInfo) {
        this.all_rendezvous({
          entreprise_id: this.entrepriseInfo,
          user_id: event.id,
          date_debut: this.debut.date,
          date_fin: this.fin.date,
        }).then(() => {
          this.events = this.getAgenda;
        });
      } else {
        this.all_rendezvous({
          entreprise_id: this.getOnlineUser.entreprise.id,
          user_id: event.id,
          date_debut: this.debut.date,
          date_fin: this.fin.date,
        }).then(() => {
          this.events = this.getAgenda;
        });
      }
    },
    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },
    scrollUp() {
      if (this.type == "day") this.$refs.calendar.scrollToTime("06:00");
    },

    filtreType(type) {
      if (this.filtreColor == type) this.filtreColor = null;
      else this.filtreColor = type;

      this.all_rendezvous({
        entreprise_id: this.getOnlineUser.entreprise.id,
        user_id: this.getOnlineUser.id,
        type: this.filtreColor != "activity.finished" ? this.filtreColor : null,
        done: this.filtreColor == "activity.finished" ? 1 : 0,
        date_debut: this.debut.date,
        date_fin: this.fin.date,
      }).then(() => {
        this.events = this.getAgenda;
      });
    },

    selectClient(client) {
      if (!client) {
        this.selectedEvent.client = null;
        this.selectedEvent.adresse = "";
        this.selectedEvent.ville = "";
        this.selectedEvent.cp = "";
        return;
      }
      this.selectedEvent.client = client;
      this.selectedEvent.adresse = client.rue;
      this.selectedEvent.ville = client.ville;
      this.selectedEvent.cp = client.cp;
    },
    chooseEnt() {
      this.all_clients({
        page: 1,
        per_page: 10,
        entreprise_id: this.selectedEvent.entreprise
          ? this.selectedEvent.entreprise.id
          : this.getOnlineUser.entreprise.id,
      });
      this.all_users({
        page: 1,
        per_page: 9999,
        entreprise_id: this.selectedEvent.entreprise
          ? this.selectedEvent.entreprise.id
          : this.getOnlineUser.entreprise.id,
      });
    },
    handleDelete() {
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },

    changeLocale(lang) {
      switch (lang) {
        case "Gr":
          lang = "de";
          break;
        case "IT":
          lang = "it";
          break;
        case "prt":
          lang = "pt";
          break;
        case "Rou":
          lang = "ro";
          break;
        default:
          break;
      }
      this.$vuetify.lang.current = lang;
    },

    setup() {
      this.hideModal("eventDetail");
      this.hideModal("affichagedonnée");
    },
  },

  computed: {
    ...mapGetters([
      "getTabs",
      "getAllUsers",
      "getOnlineUser",
      "getAllentreprises",
      "getentrepriseLoading",
      "getOnlineUserLoading",
      "getAgenda",
      "getagendaLoading",
      "getAllclients",
      "getAgendaRows",
      "getSelectedLang",
      "getclientLoading",
      "getActivity",
      "getPays",
    ]),

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },

    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },

    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },

    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },

    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isTechnicien() {
      return this.getOnlineUser.role == "user.technicien";
    },
    isUserCLient() {
      return this.getOnlineUser.role == "user.client";
    },
    callRecallUpdate() {
      return this.selectedEvent.type == "activity.appel";
    },
  },

  mounted() {
    if (!this.isCommercialBatigo) {
      this.form = { ...this.getOnlineUser.entreprise };
    }
    if (this.isSuperAdmin || this.isentrepriseAdmin || this.isAssistant)
      this.all_users({ page: 1, per_page: 10000, entreprise_id: this.form.id });
    if (
      this.isSuperAdmin ||
      this.isentrepriseAdmin ||
      this.isAssistant ||
      this.isTechnicien
    )
      this.all_clients({
        page: 1,
        per_page: 99999,
        entreprise_id: this.form.id,
      }); /// ad entreprise

    if (this.isSuperAdmin || this.isCommercialBatigo) {
      this.all_entreprises({
        page: 1,
        per_page: 10000,
      });
    }
    this.defaultcompany = this.getOnlineUser.entreprise;
    this.defaultuser = this.getOnlineUser;
    this.getEvents();
    this.changeLocale(this.getSelectedLang.iso_code);
    this.traduction();
    if (this.$route.query.id) {
      this.activity(this.$route.query.id).then(() => {
        this.selectedEvent = this.getActivity;
        this.$refs["eventDetail"].show();
      });
    }
  },
};
</script>

<style lang="scss">
header.v-sheet {
  z-index: 1;
}
.v-calendar .v-event,
.v-calendar .v-event-more {
  z-index: 0 !important;
}
@media screen and (max-width: 1117px) {
  header.v-sheet.v-toolbar--flat {
    height: 85px !important;
    .v-toolbar__content {
      height: 75px !important;
      .all {
        width: 100%;
        .filtreparte {
          display: flex;

          .mr-2 {
            width: 33% !important;
            .multiselect__single {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .secondpart {
          display: flex;
          .nbmonth {
            display: flex;
            align-items: center;
          }
          .custom-select {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1118px) {
  header.v-sheet {
    .v-toolbar__content {
      .all {
        display: flex;
        width: 100%;
        .filtreparte {
          display: flex;
          width: 50%;
          .mr-2 {
            white-space: nowrap;
            span.multiselect__single {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .secondpart {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .nbmonth {
            width: 70%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            span {
              white-space: nowrap;
              margin-top: 9px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.title {
  @media only screen and (max-width: 900px) {
    display: none;
  }
  margin: 0 4px;
}
.inner-container {
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    margin: 0;
  }
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  .content {
    @media only screen and (max-width: 750px) {
      width: 100%;
    }
    width: 95%;
    margin: 0;
  }
}
.page-header {
  width: 100%;
}
.loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .spinner-border {
    width: 4rem;
    height: 4rem;
  }
}
.rdv {
  padding: 10px 24px;
  color: #62646a;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background-color: #e9ecef;
  }
}

select {
  max-width: 130px;
  padding: 0 12px;
  font-size: 12px;
}

form select {
  max-width: 100%;
}

.body {
  padding: 4px 0;
  box-shadow: 1px 1px 24px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.legende {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  p {
    display: flex;
    align-items: center;
    margin: 0;
    transition: opacity 0.2s ease;
    cursor: pointer;
    padding: 2px 4px;
  }

  .colorOn,
  p:hover {
    opacity: 1;
  }

  .colorOn {
    box-shadow: 0 1px 4px 0 #515151;
  }

  .colorOff {
    opacity: 0.5;
  }

  span {
    margin-left: 8px;
    display: block;
    width: 20px;
    height: 20px;
  }
}

.total {
  @media only screen and (max-width: 750px) {
    display: none;
  }
  margin-right: 8px;
  font-size: 14px;
  color: rgba(15, 15, 15, 0.75);
}

.selectRed {
  background-color: #e4261b !important;
  color: #fff !important;
}
.filtreterminer {
  border-radius: 10;
  padding: 10px;
  line-height: 10px;
  border-radius: 0.27rem;
  border: 1px solid #ced4da;
  border-radius: -0.75rem;
  margin-right: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  width: 9%;
  background-color: #28367a;
}
.filtreterminer:hover {
  box-shadow: 0 0 0 0.2rem rgb(40 54 122 / 25%);
  border-color: #596cc9;
}

.event {
  height: 100%;
  padding: 6px;
  position: relative;
  border-bottom: 4px solid;
  background-color: #f8f8f8;

  h1,
  p {
    margin: 0;
    color: #151515;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  h1 {
    font-size: 14px;
    font-weight: 600;
  }

  p {
    font-size: 10px;
    line-height: 10px;
  }
  span {
    display: block;
    position: absolute;
    right: 4px;
    bottom: -2px;
    color: #151515;
    font-size: 12px;
  }
}
.v-event {
  height: 60% !important;
}

.infoDay {
  @media only screen and (max-width: 750px) {
    width: 100% !important;
    max-height: 50vh;
    margin-bottom: 24px;
    border-bottom: 1px solid #000;
  }
  width: 30%;
  position: relative;
  max-height: 96vh;
  overflow: auto;
  .cross {
    padding: 0 8px;
    background-color: red;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1;
  }
}
#affichagedonnée .center {
  align-items: flex-start;
  .blocinfo {
    padding-top: 10px;
    width: 100%;
    .statut {
      color: #e4261b;
      text-align: right;
      padding: 10px 10px 0px 0px;
      span {
        padding: 5px 15px;
        color: #ffff;
        font-weight: 700;
        border-radius: 5px;
        box-shadow: 5px 5px 10px #dcdcdc;
      }
      span.late {
        background-color: #e4261bed;
      }
      span.pending {
        background-color: rgb(233, 181, 37);
      }
      span.done {
        background-color: rgb(82, 165, 0);
      }
      .eventstatut {
        padding-top: 10px;
        padding-right: 29px;
        font-size: 17px;
      }
    }
  }

  .toppart {
    padding: 12px;
    border-bottom: 2px solid #e9ecef;
    width: 100%;
    display: flex;
    .info {
      display: flex;
      align-items: center;
      width: 84%;
      justify-content: space-between;
      .modifsup {
        button {
          margin-right: 5px;
        }
      }
    }

    .circle {
      width: 62px;
      height: 62px;
      border-radius: 50%;
      background-color: #28367a;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 800;
      margin-right: 8px;
      text-transform: uppercase;
    }
    .pro {
      background: rgb(233, 181, 37);
    }
    .App {
      background: rgb(82, 165, 0);
    }
    .tach {
      background: rgb(40, 54, 122);
    }
  }
}
.onligne {
  display: flex;
  align-items: center;
  .titre {
    color: #28367a;
  }
}
.statutevent {
  position: absolute;
  right: 2px;
  border: 1.5px solid;
  font-size: 10px;
  background-color: #f5f5f5;
  padding: 0 2px;
  line-height: 18px;
  height: 19px;
  top: 6px;
}
.statutsecond {
  position: absolute;
  right: 2px;
  top: 26px;
  font-size: 10px;
  color: #e4261b;
  padding: 0px 4px;
}
.min-calendar {
  @media only screen and (max-width: 750px) {
    width: 100% !important;
  }
  width: 65% !important;
  margin: 0;
}
.multiselect {
  width: auto;
}
</style>
